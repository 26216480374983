import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Hagedorn" mdxType="Columns">
      <h5>{`Mit Hitabis setzen Sie auf über zehn Jahre Erfahrung in Systemintegration und anderen professionellen IT-Dienstleistungen. Wir reagieren schnell und agil auf Ihre individuellen Ansprüche.`}</h5>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul">{`Wir integrieren Ihre IT-Systeme mit Maschinen und ganzen Anlagen`}</li>
        <li parentName="ul">{`Wir erstellen Ihre ganz individuelle Softwarelösung`}</li>
        <li parentName="ul">{`Wir begleiten Sie von der IT-Planung bis Systemintegration`}</li>
        <li parentName="ul">{`Wir betreuen, warten und entwickeln Ihre IT-Strukturen weiter`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      